/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */




// updating service worker when new content is available
// see: https://github.com/gatsbyjs/gatsby/issues/9087

exports.onServiceWorkerUpdateReady = () => window.location.reload(true);
